import React from 'react';
import { Layout } from '../components/ui/Layout';
import { Text } from '../components/ui/typography/Text';
import { TextPage } from '../components/ui/text/TextPage';
import { Link } from 'gatsby';
import { TextHeader } from '../components/layout/TextHeader';
import loadable from '@loadable/component';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Helmet = loadable(() => import('react-helmet'));

export default () => (
	<Layout>
		<Helmet>
			<title>PageSpeed.cz - Zásady ochrany osobních údajů</title>
		</Helmet>
		<TextHeader headline="Zásady ochrany osobních údajů" />
		<TextPage>
			<h2>Zpracování osobních údajů pro PageSpeed.cz</h2>

			<Text>
				Internetová stránka pagespeed.cz (dále jen „Stránka“) je provozována především za účelem testování
				rychlosti webových stránek a zprostředkování služeb v oblasti optimalizace a zrychlování webů.
			</Text>
			<Text>
				Za tímto účelem prostřednictvím Stránky nabízíme služby v oblasti auditů, analýz a optimalizace webových
				stránek. (dále jen „služby“).
			</Text>
			<Text>
				To se sebou jako svou nutnou součást nese i práci s osobními údaji. Protože chceme, abyste byli co
				nejlépe informováni o tom, co s takto nashromážděnými osobními údaji děláme, sepsali jsme pro Vás tyto
				Zásady ochrany osobních údajů, a to v souladu s nařízením Evropského parlamentu a Rady (EU) č. 2016/679,
				o ochraně fyzických osob v souvislosti se zpracováním osobních údajů (dále jen „GDPR“).
			</Text>
			<Text>V zásadách ochrany osobních údajů se dozvíte zejména:</Text>
			<div className="anchor-before">
				<ul>
					<li>
						<a href="#part-1">Jaké informace</a> shromažďujeme a z jakého důvodu.
					</li>
					<li>
						<a href="#part-2">Jakým způsobem</a> tyto informace využíváme.
					</li>
					<li>
						<a href="#part-3">Jaké jsou Vaše práva</a> ohledně námi využívaných soukromých informací a jakým
						způsobem je u nás uplatnit.
					</li>
				</ul>
			</div>
			<h2 id="part-1" className="anchor">
				Informace, které o Vás shromažďujeme
			</h2>
			<div className="anchor-before">
				<Text>
					V souvislosti s testováním domén, je to emailová adresa. Při objednávce auditu je to také telefon a
					kontaktní informace sloužící k fakturaci. Stránka rovněž pracuje se{' '}
					<Link to="/cookies">soubory cookies</Link>. Bez shromažďování těchto osobních údajů bychom Vám
					nebyli schopni poskytnout veškeré poskytované služby.
				</Text>
			</div>
			<h2 id="part-2" className="anchor">
				Jakým způsobem informace využíváme
			</h2>
			<Text>
				Osobní údaje, které shromažďujeme, využíváme především za účelem zajištění smluvní dokumentace v
				souvislosti s objednáním služeb či prostřednictvím Stránky a dále za účelem marketingu.
			</Text>
			<Text>
				Shromážděné údaje archivujeme po dobu nezbytně nutnou v souvislosti se smluvním vztahem a případným
				sporem z něj vzniklým. S ohledem na to, že soudní spor může vzniknout v době 3 let od ukončení smluvního
				vztahu (tedy nákupu zboží či poskytnutí služeb), archivujeme nashromážděné údaje v nezbytném rozsahu po
				dobu 4 let.
			</Text>
			<Text>
				Osobní údaje využívané k e-mailovému marketingu budou zpracovávány po dobu fungování Stránky, kdy při
				jejím zrušením budou všechny zpracované osobní údaje odstraněny. K dřívějšímu výmazu shromážděných údajů
				může dojít na základě žádosti subjektu údajů, tak jak je uvedeno níže.
			</Text>
			<Text>
				Vaše osobní údaje zpracováváme my, je však možné, že v některých případech budou Vaše osobní údaje
				zpracovávat i specializovaní externisté. Vybíráme vždy pečlivě jen takové zpracovatele osobních údajů,
				kteří nám poskytnou maximální záruky technologického a organizačního zabezpečení ochrany předávaných
				osobních údajů.
			</Text>
			<div className="anchor-before">
				<Text>
					Může jednat o externí mzdovou účetní společnost, daňového či právního poradce nebo o společnost
					realizující platby.
				</Text>
			</div>
			<h2 id="part-3" className="anchor">
				Jaká máte práva a jak jich využít
			</h2>
			<h3>Právo na zpřístupnění informací a právo na jejich opravu</h3>
			<Text>
				Kdykoliv v budoucnu budete chtít, můžete nás zasláním zprávy na e-mailovu adresu info@pagespeed.cz
				požádat o zaslání potvrzení, zda jsou či nejsou Vaše osobní údaje zpracovávány. V případě, že Vaše údaje
				námi budou zpracovány, můžeme Vás na Vaši žádost nad rámec informací poskytnutých v těchto Zásadách
				ochrany osobních údajů informovat i o případných třetích osobách, kterým byly nebo budou Vaše osobní
				údaje zpřístupněny a pokud nezískáme osobní údaje od Vás, máte právo na veškeré nám dostupné informace o
				tom, odkud jsme Vaše osobní informace získali.
			</Text>
			<Text>
				Pokud o Vás budeme zpracovávat Vaše osobní údaje nepřesně, můžete nás na tuto skutečnost zasláním zprávy
				na e-mailovou adresu info@pagespeed.cz upozornit, my pak bez zbytečného odkladu nepřesné osobní údaje
				opravíme.
			</Text>
			<h3>Právo vznést námitky proti zpracování osobních údajů</h3>
			<Text>
				V případě, že budeme Vaše osobní údaje zpracovávat na základě námi oprávněného zájmu, máte právo proti
				takovému zpracování vznést námitky, a to zasláním zprávy na e-mailovou adresu info@pagespeed.cz. Pokud
				takovou námitku podáte, nebudeme moci Vaše osobní údaje zpracovávat, dokud neprokážeme, jaký závažný
				oprávněný důvod pro zpracování máme, a že tento náš důvod převáží nad Vašimi zájmy, Vašimi právy a
				svobodami či nad výkonem nebo obhajobou právních nároků.
			</Text>
			<Text>
				Pokud bychom zpracovávali Vaše osobní údaje pro účely přímého marketingu (např. z důvodů zasílání
				obchodních sdělení), můžete vznést na výše uvedenou e-mailovou adresu námitky, proti takovému
				zpracování, přičemž po vznesení takové námitky již nebudeme tyto údaje pro účely přímého marketingu
				zpracovávat.
			</Text>
			<h3>Právo na omezení práce s osobními údaji</h3>
			<Text>Máte právo, abychom omezili jakékoliv zpracování Vašich osobních údajů včetně jejich vymazání:</Text>
			<ol>
				<li>
					Pokud nám dáte vědět, že jsou námi shromážděné osobní údaje nepřesné, a to až do ověření jejich
					správnosti.
				</li>
				<li>
					Pokud bude zpracování Vašich osobních údajů protiprávní a Vy požádáte zasláním zprávy na e-mailovou
					adresu info@pagespeed.cz namísto o vymazání o omezení jejich použití.
				</li>
				<li>
					Pokud Vaše osobní údaje již sice nebudeme potřebovat pro zajištění našich služeb, ale Vy je budete
					požadovat pro určení, výkon nebo obhajobu Vašich právních nároků.
				</li>
				<li>
					Pokud vznesete námitku proti zpracování dle odstavce výše, a to do doby, než ověříme, zda naše
					důvody zpracování převažují nad Vašimi zájmy.
				</li>
			</ol>
			<h3>Právo být zapomenut (právo na vymazání osobních údajů)</h3>
			<Text>V případě, že zjistíte, že zpracováváme Vaše osobní údaje:</Text>
			<ol>
				<li>Přestože již není jejich zpracování potřebné pro účely, kvůli kterým jsme je získali.</li>
				<li>
					Odvoláte souhlas s jejich zpracováním a zároveň nebude existovat žádný další důvod pro jejich
					zpracování (samozřejmě jen v případech, kde zpracováváme Vaše osobní údaje na základě Vašeho
					souhlasu).
				</li>
				<li>
					Pokud vznesete námitku podle odstavce uvedeného výše a my Vám nebudeme schopni prokázat oprávněné
					důvody pro jejich zpracování, které převáží nad Vašimi zájmy, Vašimi právy a svobodami či nad
					výkonem nebo obhajobou právních nároků.
				</li>
				<li>Neoprávněně.</li>
			</ol>
			<Text>
				Máte právo na to, abychom bez zbytečného odkladu od oznámení takovýchto skutečností zasláním zprávy na
				e-mailovou adresu info@pagespeed.cz takto zpracovávané osobní údaje vymazali. Údaje pak nemůžeme ani na
				Vaši žádost vymazat, pokud bude jejich zpracování nezbytné pro výkon práva na svobodu projevu a
				informace, pro splnění některé naší právní povinnosti nebo pro splnění úkolu prováděného ve veřejném
				zájmu, či pro určení, výkon nebo obhajobu našich právních nároků.
			</Text>
			<h3>Právo na poskytnutí údajů ve strojově čitelné formě</h3>
			<Text>
				Pokud nás zasláním zprávy na e-mailovou adresu info@pagepspeed.cz požádáte, abychom Vám poskytli námi
				zpracovávané osobní údaje, zašleme Vám je ve strukturovaném běžně používaném a strojově čitelném formátu
				(např. některý z tabulkových formátů), pokud takto údaje zpracováváme. Pokud nás požádáte, abychom Vaše
				osobní údaje zaslali jinému správci osobních údajů, máme povinnost tak učinit, ovšem opět jen v případě,
				že je takto již zpracováváme.
			</Text>
			<h3>Právo kdykoliv odvolat souhlas se zasíláním obchodních sdělení</h3>
			<Text>
				V případě, že od nás již nebudete chtít dostávat obchodní sdělení, můžete odvolat souhlas s jejich
				zasíláním kdykoliv i bez udání důvodů buď kliknutím na označený odkaz, který bude součástí každého
				obchodního sdělení, nebo omezením ve Vašem profilu vytvořeném na základě registrace na našich webových
				stránkách, popřípadě zasláním zprávy na e-mailovou adresu info@pagespeed.cz.
			</Text>
			<h3>Právo podat stížnost u Úřadu na ochranu osobních údajů</h3>
			<Text>
				V případě, že podle Vašeho názoru nenaplníme všechny naše právní povinnosti vzniklé v souvislosti se
				zpracováním Vašich osobních údajů, můžete se obrátit na Úřad pro ochranu osobních údajů:
			</Text>
			<ul>
				<li>na adrese jejich sídla: Pplk. Sochora 27, Praha 7, PSČ 170 00,</li>
				<li>na e-mailu posta@uoou.cz,</li>
			</ul>
			<Text>či jakoukoliv jinou cestou, kterou bude Úřad na ochranu osobních údajů akceptovat.</Text>
			<Text>
				Bližší informace o úřadu najdete na webových stránkách{' '}
				<OutboundLink href="https://www.uoou.cz/" target="_blank" rel="noopener noreferrer">
					www.uoou.cz
				</OutboundLink>
				.
			</Text>
		</TextPage>
	</Layout>
);
